import * as React from "react"
import { Main } from "../components/layout"
import ContactWrapper from "../components/pages/Contact/ContactWrapper"

// markup
const ContactPage = () => {
	return (
		<>
			<Main>
				<ContactWrapper />
			</Main>
		</>
	)
}

export default ContactPage
