import React from "react"
import Paragraph from "../../../../components/shared/Paragraph"
import SubHeading from "../../../../components/shared/SubHeading"
import { Link } from "gatsby"
import fb_icon from "../../../../assets/icons/fb.svg"
import insta_icon from "../../../../assets/icons/insta.svg"
import twitter_icon from "../../../../assets/icons/twitter.svg"
import linkedin_icon from "../../../../assets/icons/linkedin.svg"
import { Button, Image } from "react-bootstrap"
import * as styles from "./styles.module.scss"

const GetTouch = () => {

	return (
		<>
			<SubHeading className="fst-italic">Get in touch</SubHeading>
			<Paragraph className="fw-bold mb-0">Email</Paragraph>
			<Link href="" className="text-secondary text-decoration-none" style={{ fontSize: "13px" }}>
				team@galeahealth.com
			</Link>
			<Paragraph className="fw-bold mt-4">Social media</Paragraph>
			{/* <Link href="https://www.facebook.com/galeahealth/">
				<Image src={fb_icon} fluid />
			</Link> */}
			<Link href="https://www.instagram.com/galeahealth/" >
				<Image src={insta_icon} fluid />
			</Link>
			{/* <Link href="https://twitter.com/GaleaHealth" className="ms-2">
				<Image src={twitter_icon} fluid />
			</Link> */}
			<Link href="https://www.linkedin.com/company/galea-health/?viewAsMember=true" className="ms-2">
				<Image src={linkedin_icon} fluid />
			</Link>
			<Paragraph className={styles.paragraphSmall}>
				Want to join our therapist and performance coach network?{" "}
				<Link to="/providerSignUp" className="text-secondary">
					Sign up here
				</Link>
			</Paragraph>
			<Paragraph className={styles.paragraphSmall}>
				Want to join our Athlete Advocate community?
				<Link href="advocateForm/" className="text-secondary">
					Join here
				</Link>
			</Paragraph>
			{/* <div className="text-md-start text-center">
				<Button variant="secondary" className={styles.btn_faq}>
					visit our faq page
				</Button>
			</div> */}
		</>
	)
}

export default GetTouch
