import React from "react"
import Buttons from "../../../../components/shared/Button"
import Form from "react-bootstrap/Form"
import * as styles from "./styles.module.scss"
import axios from "axios"
import Modal from "react-bootstrap/Modal"
import { Button } from "react-bootstrap"

const ContactForm = () => {
	const [fullName, setFullName] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [message, setMessage] = React.useState("")
	const [show, setShow] = React.useState(false)
	const [show1, setShow1] = React.useState(false)


	const handleClose = () => setShow(false)
	const handleClose1 = () => setShow1(false)

	const handleContact = () => {
		const contactPayload = {
			email: email,
			message: message,
			name: fullName,
		}
		console.log("contact output is",contactPayload)
		axios.post("https://api.galeahealth.com/admin/action/contact", contactPayload)
			.then((res) => {
				
				if (res.status === 200) {
					// setMessage("")
					// setEmail("")
					// setFullName("")
					setShow(true)
				}
			})
			.catch((err) => {
				setShow1(true)
			})
	}
	return (
		<>
		<Form className={styles.formContact}>
			<Form.Group className="mb-3" controlId="formBasicName">
				<Form.Control onChange={(e)=>setFullName(e.target.value)} type="text"  placeholder="Full name*" />
			</Form.Group>

			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Control onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Email address*" />
			</Form.Group>
			<Form.Group className="mb-3" controlId="formBasicSubject">
				<Form.Control onChange={(e)=>setMessage(e.target.value)} type="text" placeholder="Subject*" />
			</Form.Group>
			<Form.Control as="textarea" className="mb-3" placeholder="Message*" style={{ height: "140px" }} />

			<Button  onClick={handleContact} className="mt-0 w-100">Send</Button>
		</Form>
			<Modal centered size="lg" show={show} onHide={handleClose}>
				<Modal.Body>
					<div className="text-center pb-3 pt-5">
						<span className="patti"></span>
						<h6 className="patti">Galea Health</h6>
						<p>Thank you for your message!</p>
						<Button
							className="btn btn-primary"
							style={{ backgroundColor: "#007bff", color: "#fff" }}
							onClick={handleClose}
						>
							OK
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal centered size="lg" show={show1} onHide={handleClose1}>
				<Modal.Body>
					<div className="text-center pb-3 pt-5">
						<span className="patti"></span>
						<h6 className="patti">Galea Health</h6>
						<p>Please fill out all fields.</p>
						<Button
							className="btn btn-primary"
							style={{ backgroundColor: "#007bff", color: "#fff" }}
							onClick={handleClose1}
						>
							OK
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ContactForm
