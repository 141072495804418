import React from "react"
import Section from "../../../../components/shared/Section"
import { Col, Container, Row } from "react-bootstrap"
import SectionHeading from "../../../../components/shared/Sectionheading"
import Paragraph from "../../../../components/shared/Paragraph"
import ContactForm from "../ContactForm"
import GetTouch from "../GetTouch"

const ContactWrapper = () => {
	return (
		<Section>
			<Container>
				<Row className="justify-content-center" >
					<Col md={11}>
						<SectionHeading className="fst-italic">Let’s talk</SectionHeading>
						<Paragraph>
							If you have any questions, suggestions, or are looking to partner with Galea Health, <br/> fill out our contact
							form and we will get back to you soon.
						</Paragraph>
					</Col>
				</Row>
				<Row className="mt-3 justify-content-center" >
					<Col md={5} className="mb-5 ">
						<ContactForm />
					</Col>
					<Col md={{ span: 5, offset: 1 }} className="mb-5">
						<GetTouch />
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default ContactWrapper
